import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BiArrowBack } from 'react-icons/bi';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import { usePayment } from '../../hooks/payment';
import { useTCS } from '../../hooks/tcs';

import CardPayment from '../../components/Checkout/CardPayment';
import Details, { IDetails } from '../../components/Checkout/Details';
import HeaderCheckout from '../../components/Checkout/HeaderCheckout';
import PaymentTypeSelection from '../../components/Checkout/PaymentMethods';
import Summary from '../../components/Checkout/Summary';
import WireTransfer from '../../components/Checkout/WireTransfer';
import Loading from '../../components/Loading/index';
import CheckoutService from '../../services/checkout/CheckoutService';
import ICheckout from '../../services/checkout/interfaces/ICheckoutDTO';
import { PaymentMethods } from '../../services/payment/interfaces/ICheckoutPaymentDTO';
import GetnetFingerprint from '../../utils/getnet-fingerprint';

import JPMorganEncryption from '../../utils/jpmorgan-encryption';
import { ContainerUiBody } from './style';
import CheckoutPayment from '../../components/Checkout/CheckoutPayment';

export interface IParamsRoute {
  id: string;
}

const Checkout: React.FC = () => {
  const { t } = useTranslation('checkout');
  const {
    setCheckoutPayment,
    setCheckoutId,
    paymentMethod,
    setPaymentMethod,
    paymentOrderNumber,
  } = usePayment();
  const { checkAcceptance } = useTCS();
  const { goBack } = useHistory();
  const { search } = useLocation();
  const { params } = useRouteMatch<IParamsRoute>();

  const [loading, setLoading] = useState(true);
  const [checkout, setCheckout] = useState<ICheckout>({} as ICheckout);

  const handleButtonBackHistory = useCallback(() => {
    setLoading(true);
    if (checkout.transactionType !== PaymentMethods.WIRE_TRANSFER) {
      CheckoutService.leaveCheckout(params.id).then(() => {
        if (/invoices/.test(checkout?.errorUrl)) {
          window.history.back();
        } else {
          window.location.href = `${checkout?.errorUrl}`;
        }
      });
    } else {
      window.location.href = `${checkout?.errorUrl}`;
    }
  }, [checkout, params.id]);

  const getPaymentMethodComponent = useMemo((): JSX.Element => {
    const paymentMethods =
      (checkout.transactionType as PaymentMethods) || paymentMethod;

    switch (paymentMethods) {
      case PaymentMethods.CREDIT || PaymentMethods.DEBIT:
        if (checkout.gateway === 'JPMorganGatewayCheckout')
          return <CheckoutPayment checkout={checkout} />;

        return <CardPayment checkout={checkout} paymentType={paymentMethods} />;
      case PaymentMethods.WIRE_TRANSFER:
        return (
          <WireTransfer
            checkoutId={params.id}
            paymentOrderNum={paymentOrderNumber}
            currency={checkout.paymentCurrency}
            salesOrganization={checkout.salesOrganization}
          />
        );
      default:
        return <CardPayment checkout={checkout} paymentType={paymentMethods} />;
    }
  }, [checkout, params.id, paymentMethod, paymentOrderNumber]);

  const handleCheckoutUpdate = useCallback((updatedCheckout: ICheckout) => {
    setCheckout(updatedCheckout);
  }, []);

  useEffect(() => {
    checkAcceptance(search).then((r) => {
      if (r === false) return;

      CheckoutService.getCheckout(params.id)
        .then(async (c) => {
          const { body } = c.data;
          if (body.decision === null) {
            setCheckout(body);
            setCheckoutPayment(body);
            setCheckoutId(params.id);
            if (['JPMorganGatewayUser'].includes(body.gateway || '')) {
              JPMorganEncryption(() => {
                setLoading(false);
              });
            }
            if (/^Getnet/i.test(body.gateway || '')) {
              GetnetFingerprint(params.id, () => {
                // setLoading(false);
              });
            }
            setLoading(false);
          } else if (c.data.body.transactionType === 'WIRE_TRANSFER') {
            setCheckout(c.data.body);
            setCheckoutPayment(c.data.body);
            setCheckoutId(params.id);
            setPaymentMethod(PaymentMethods.WIRE_TRANSFER);
            setLoading(false);
          } else {
            Swal.fire({
              title: 'Oops!',
              text: t('This checkout has already been processed.'),
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then(() => {
              window.location.href = `${body.errorUrl}`;
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(JSON.stringify(err));
          Swal.fire({
            title: 'Oops!',
            text:
              err.response.data.message || t('Error trying to get checkout'),
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => {
            goBack();
          });
        });
    });
  }, [
    params.id,
    setCheckoutPayment,
    t,
    setCheckoutId,
    search,
    checkAcceptance,
    goBack,
    setPaymentMethod,
  ]);

  return (
    <>
      <HeaderCheckout />
      {loading ? (
        <Loading />
      ) : (
        <ContainerUiBody>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <Grid
                container
                direction="row"
                alignItems="center"
                alignContent="center"
              >
                <Grid item className="arrow-back" xs={12}>
                  <button type="button" onClick={handleButtonBackHistory}>
                    <BiArrowBack />
                    {t('Back')}
                  </button>
                </Grid>
                <Grid item xs={12}>
                  <PaymentTypeSelection
                    gateway={checkout?.gateway}
                    salesOrganization={checkout?.salesOrganization}
                  />
                </Grid>
              </Grid>
              {getPaymentMethodComponent}
            </Grid>

            <Grid item xs={12} sm={4}>
              <Summary
                paymentCurrency={checkout?.paymentCurrency}
                paymentAmount={checkout?.paymentAmount}
                issuedCurrency={checkout?.issuedCurrency}
                totalAmount={checkout?.totalAmount}
                totalIrfValue={checkout?.totalIrfValue}
                exchangeRateFormatted={checkout?.exchangeRateFormatted}
                customerName={checkout?.customer.name}
                gateway={checkout?.gateway}
                totalInterestAmount={checkout?.totalInterestAmount}
              />
            </Grid>
          </Grid>
          <Grid container id="details">
            <Grid item xs={12}>
              <Details
                documents={checkout?.documents as IDetails[]}
                detailsTotal={checkout}
                handleCheckoutUpdate={handleCheckoutUpdate}
                gateway={checkout?.gateway}
              />
            </Grid>
          </Grid>
        </ContainerUiBody>
      )}
    </>
  );
};

export default Checkout;
